@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

html {
    /*height: 100%;*/
}

body {
    margin: 0;
    padding: 0;
}

.leaflet-container {
    height: calc(100vh - 80px);
}

.add-trip {
    height: calc(100vh - 80px);
}

